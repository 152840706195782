import {useModal} from '@/components/basic/modal';
import {View} from 'react-native';
import {useInnerStyle} from './wallet-new.hooks';
import {CloseIcon} from './svg.variables';
import theme from '@/style';
import Text from '@/components/basic/text';
import React from 'react';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';

export interface QuestionModalOptions {
  title: string;
  content: string;
}

export function useQuestionModal(
  {title, content}: QuestionModalOptions = {title: '-', content: '-'},
) {
  const {size} = useInnerStyle();
  const maxWidth = size.calcActualSize(234);
  const {renderModal, show, hide} = useModal(
    <View
      style={[
        theme.flex.col,
        theme.flex.center,
        {
          rowGap: size.xxl,
        },
      ]}>
      <View
        style={[
          theme.flex.col,
          theme.background.white,
          {
            rowGap: size.l,
            padding: size.l * 2,
            borderRadius: size.calcActualSize(16),
          },
        ]}>
        <Text calc size="medium" blod main style={[theme.font.center]}>
          {title}
        </Text>
        <Text
          calc
          size="medium"
          second
          style={[{maxWidth: maxWidth}, theme.font.center]}>
          {content}
        </Text>
      </View>
      <NativeTouchableOpacity onPress={() => hide()}>
        <CloseIcon />
      </NativeTouchableOpacity>
    </View>,
    {
      overlayStyle: {
        backgroundColor: 'transparent',
        shadowColor: 'transparent',
      },
    },
  );

  return {
    renderModal,
    show,
  };
}
