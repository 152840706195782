import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import React, {FC, useMemo, useState} from 'react';
import {useInnerStyle} from './wallet-new.hooks';
import theme from '@/style';
import LazyImage from '@/components/basic/image';
import Text from '@/components/basic/text';
import Price from '@/components/business/price';
import BoxShadow from '@/components/basic/shadow';
import {View} from 'react-native';

export interface WalletListItemProps {
  onPress?: () => void;
  gameName?: string;
  icon?: string;
  price?: number;
}

const WalletListItem: FC<WalletListItemProps> = ({
  onPress,
  gameName,
  icon,
  price,
}) => {
  const {size, walletStyle} = useInnerStyle();
  const column = 3;
  const imageSize = size.calcActualSize(24);
  const [pressing, setPressing] = useState(false);

  const itemWidth = useMemo(() => {
    return (size.screenWidth - 2 * size.l - (column - 1) * size.s) / column;
  }, [size]);
  return (
    <NativeTouchableOpacity
      onPressIn={() => setPressing(true)}
      onPressOut={() => setPressing(false)}
      activeOpacity={1}
      onPress={onPress}
      style={[{width: itemWidth}]}>
      <BoxShadow
        style={[
          theme.fill.fillW,
          theme.overflow.hidden,
          {borderRadius: size.calcActualSize(8)},
        ]}
        shadowStyle={{
          out: {
            x: 0,
            y: size.calcActualSize(4),
            blur: size.calcActualSize(8),
            color: '#d0d0ed5b',
          },
        }}>
        <View style={[theme.position.rel, theme.background.white]}>
          <View
            style={[
              theme.fill.fill,
              theme.position.abs,
              pressing ? walletStyle.itemGray : null,
            ]}
          />
          <View
            style={[
              {
                paddingVertical: size.l,
              },
              theme.flex.col,
              theme.flex.center,

              theme.overflow.hidden,
            ]}>
            <LazyImage
              imageUrl={icon || ''}
              occupancy="#0000"
              height={imageSize}
            />
            <Text
              blod
              main
              size="medium"
              calc
              style={{
                marginTop: size.xxs / 2,
              }}>
              {gameName}
            </Text>
            <Price
              textProps={{
                size: 'small',
                second: true,
              }}
              style={[{marginTop: size.xxs}]}
              price={price}
              suffixUnit="K"
              spacing
            />
          </View>
        </View>
      </BoxShadow>
    </NativeTouchableOpacity>
  );
};

export default WalletListItem;
