import {http} from '@/utils';

export interface GameWalletListItem {
  bgImgUrl: string;
  gameType: string;
  moduleName: string;
  userBalance: number;
}

export function getGameWalletList() {
  return http.get<null, GameWalletListItem[]>(
    'app/personalWallet/getGameWallet',
  );
}
