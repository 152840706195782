import {StatusBar, StyleSheet} from 'react-native';
import {useScreenSize} from '../hooks/size.hooks';
import theme from '@/style';
import globalStore from '@/services/global.state';

export function useSize() {
  const {screenWidth, screenHeight, calcActualSize} = useScreenSize();

  return {
    screenWidth,
    screenHeight,
    calcActualSize,
    rechargeButtonWidth: calcActualSize(88),
    rechargeButtonHeight: calcActualSize(32),
    topClipHeight: globalStore.isWeb ? 44 : StatusBar.currentHeight || 0,
    zero: theme.paddingSize.zorro,
    topBgWidth: screenWidth,
    topBgHeight: calcActualSize(305),
    xxs: calcActualSize(theme.paddingSize.xxs), // 4
    xs: calcActualSize(theme.paddingSize.xs), // 6
    s: calcActualSize(theme.paddingSize.s), // 8
    m: calcActualSize(theme.paddingSize.m), // 10
    l: calcActualSize(theme.paddingSize.l), // 12
    xl: calcActualSize(theme.paddingSize.xl), // 14
    xxl: calcActualSize(theme.paddingSize.xxl), // 16
  };
}

export function useInnerStyle() {
  const size = useSize();
  const navHeight = 52;
  const walletStyle = StyleSheet.create({
    nav: {
      // 目前暂不考虑覆盖状态栏的情况
      height: navHeight,
    },
    topArea: {
      top: -size.topClipHeight,
      left: 0,
    },
    scrollView: {
      flex: 1,
    },
    navFloat: {
      zIndex: 1,
    },
    itemGray: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      // left: 0,
      // top: 0,
    },
  });

  const totalWalletStyle = StyleSheet.create({
    rechargeButton: {
      height: size.rechargeButtonHeight,
      backgroundColor: theme.basicColor.transparent,
      paddingVertical: 0,
      paddingHorizontal: 0,
    },
  });
  return {
    size,
    walletStyle,
    totalWalletStyle,
  };
}
