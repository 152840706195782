import theme from '@/style';
import React, {FC} from 'react';
import {View, Image} from 'react-native';
import {useInnerStyle} from './wallet-new.hooks';
import {walletIcon} from './wallet.variable';
import Text from '@/components/basic/text';
import {QuestionIcon} from './svg.variables';
import Button from '@basicComponents/button';

import Price from '@/components/business/price';
import LinearGradient from '@/components/basic/linear-gradient';
import {useTranslation} from 'react-i18next';
import BoxShadow from '@/components/basic/shadow';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {useQuestionModal} from './question.modal';
import globalStore from '@/services/global.state';

export interface WalletHeaderProps {
  onRecharge?: () => void;
  totalWallet?: number;
}

const WalletHeader: FC<WalletHeaderProps> = ({onRecharge, totalWallet = 0}) => {
  const {size, totalWalletStyle} = useInnerStyle();
  const walletSize = size.calcActualSize(40);
  const questionSize = size.calcActualSize(16);
  const i18n = useTranslation();
  const {renderModal, show} = useQuestionModal({
    title: i18n.t('wallet.total'),
    content: i18n.t('wallet.tip.total'),
  });
  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.between,
        theme.flex.centerByCol,
        {paddingHorizontal: size.l},
      ]}>
      <View style={[theme.flex.row, {columnGap: size.s}]}>
        <Image
          style={{width: walletSize, height: walletSize}}
          source={walletIcon}
        />
        <View style={[theme.flex.col, theme.flex.alignStart]}>
          <View
            style={[
              theme.flex.row,
              theme.flex.center,
              {
                columnGap: size.xxs,
              },
            ]}>
            <Text
              calc
              fontSize={theme.fontSize.s}
              color={theme.basicColor.white}>
              {i18n.t('wallet.total')}
            </Text>
            <NativeTouchableOpacity onPress={show}>
              <QuestionIcon
                style={{
                  width: questionSize,
                  height: questionSize,
                }}
              />
            </NativeTouchableOpacity>
          </View>
          <Price
            style={[{columnGap: size.xxs}, theme.flex.alignEnd]}
            currencyTextProps={{
              fontSize: theme.fontSize.l,
            }}
            textProps={{
              fontSize: theme.fontSize.xl,
              color: theme.basicColor.white,
            }}
            price={totalWallet}
          />
        </View>
      </View>
      <Button
        buttonStyle={[totalWalletStyle.rechargeButton, theme.overflow.hidden]}
        radius={size.calcActualSize(23)}
        onPress={onRecharge}>
        <BoxShadow
          shadowStyle={{
            inset: {
              x: 0,
              y: size.calcActualSize(1),
              blur: 0,
              color: '#ffffff33',
            },
            out: {
              x: 0,
              y: size.calcActualSize(1),
              blur: size.calcActualSize(4),
              color: '#0000003f',
            },
          }}
          style={{
            width: size.rechargeButtonWidth,
            height: size.rechargeButtonHeight,
          }}>
          <LinearGradient
            style={[
              theme.fill.fill,
              ...(!globalStore.isH5
                ? [theme.flex.centerByCol, {paddingTop: size.xs}]
                : [theme.flex.center, {paddingVertical: size.s}]),
            ]}
            colors={theme.basicColor.primaryLinearGradient}
            start={{x: 0, y: 0}}
            end={{x: 1, y: 0}}>
            <Text
              fontSize={theme.fontSize.s}
              blod
              calc
              color={theme.basicColor.white}>
              {i18n.t('wallet.recharge')}
            </Text>
          </LinearGradient>
        </BoxShadow>
      </Button>
      {renderModal}
    </View>
  );
};

export default WalletHeader;
