import theme from '@/style';
import React from 'react';
import {View} from 'react-native';
const iconSize = 14;
export default () => (
  <View style={[{width: iconSize, height: iconSize}]}>
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 5.5L4.38889 8.61111L10 3"
        stroke={theme.basicColor.primary}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </View>
);
