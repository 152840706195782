import React from 'react';

import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import theme from '@/style';
import {View} from 'react-native';
import {goBack, goCS} from '@/utils';
import {BackIcon, ServiceIcon} from './svg.variables';
import {useInnerStyle} from './wallet-new.hooks';
export interface NormalNavProps {
  title: string;
  onRules?: () => void;
  hideBack?: boolean;
}

const NormalNav: React.FC<NormalNavProps> = ({title, hideBack}) => {
  const {walletStyle, size} = useInnerStyle();
  const navBackWrapSize = size.calcActualSize(24);
  const navBackIconSize = size.calcActualSize(16);
  return (
    <View
      style={[
        theme.fill.fill,
        theme.flex.center,
        theme.position.rel,
        {paddingHorizontal: size.l, paddingVertical: size.m},
        theme.flex.row,
      ]}>
      {!hideBack && (
        <NativeTouchableOpacity
          style={[
            {
              width: navBackWrapSize,
              height: navBackWrapSize,
            },
            theme.position.abs,
            theme.fill.fillH,
            theme.flex.center,
            walletStyle.navFloat,
            {
              left: size.l,
            },
          ]}
          onPress={goBack}>
          <BackIcon
            width={navBackIconSize}
            height={navBackIconSize}
            stroke={theme.basicColor.white}
          />
        </NativeTouchableOpacity>
      )}
      <Text
        fontSize={theme.fontSize.l}
        blod
        color={theme.basicColor.white}
        calc>
        {title}
      </Text>
      <NativeTouchableOpacity
        style={[
          {
            width: navBackWrapSize,
            height: navBackWrapSize,
          },
          theme.position.abs,
          theme.fill.fillH,
          theme.flex.center,
          walletStyle.navFloat,
          {
            right: size.l,
          },
        ]}
        onPress={goCS}>
        <ServiceIcon
          width={navBackWrapSize}
          height={navBackWrapSize}
          stroke={theme.basicColor.white}
        />
      </NativeTouchableOpacity>
    </View>
  );
};

export default NormalNav;
