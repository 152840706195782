import theme from '@/style';
import React, {useCallback, useState} from 'react';
import {View} from 'react-native';
import Animated from 'react-native-reanimated';
import {useInnerStyle} from './wallet-new.hooks';
import {useNavChangeScrollAnimate} from '@/components/utils/animate';
import {useTranslation} from 'react-i18next';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {goBack, goTo} from '@/utils';
import {LazyImageBackground} from '@/components/basic/image';
import {walletBackground} from './wallet.variable';
import WalletHeader from './wallet-header';
import globalStore from '@/services/global.state';
import WalletMenus from './wallet-menus';
import WalletList from './wallet-list';
import {GameWalletListItem, getGameWalletList} from './wallet.service';
import {useFocusEffect} from '@react-navigation/native';
import NormalNav from './normal-nav';

const WalletNew = () => {
  const {size, walletStyle} = useInnerStyle();
  const [login, setLogin] = useState(false);
  // 全游戏钱包余额
  const [amount, setAmount] = useState(0);
  // 游戏钱包余额列表
  const [gameWalletList, setGameWalletList] = useState<GameWalletListItem[]>(
    [],
  );
  const initGameWallet = () => {
    globalStore.globalLoading.next(true);
    getGameWalletList()
      .then(list => setGameWalletList(list))
      .finally(() => globalStore.globalLoading.next(false));
  };
  const handleMoney = useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      setLogin(!!token);
    });
    // 以防后台充值后金额依然没有更新
    globalStore.updateAmount.next({});
    const moneySub = globalStore.amountChanged.subscribe(res => {
      setAmount(res.current);
    });
    initGameWallet();
    return () => {
      sub.unsubscribe();
      moneySub.unsubscribe();
    };
  }, []);
  useFocusEffect(handleMoney);

  const toRecharge = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    // 跳转recharge
    goTo('Recharge');
  };

  const toWithdraw = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    // 跳转withdraw
    goTo('Withdraw');
  };

  const toTransfer = () => {
    // 跳转transfer
    if (!login) {
      goTo('Login');
      return;
    }
    goTo('Transfer');
  };

  const {scrollHandler, normalNavStyle, detailNavStyle} =
    useNavChangeScrollAnimate();
  const {t} = useTranslation();
  return (
    <View
      style={[
        theme.position.rel,
        {height: size.screenHeight},
        theme.overflow.hidden,
        theme.background.lightGrey,
      ]}>
      <Animated.ScrollView
        style={[walletStyle.scrollView, theme.position.rel, theme.flex.col]}
        scrollEventThrottle={16}
        onScroll={scrollHandler}
        stickyHeaderIndices={[0]}>
        <View style={[theme.position.rel, theme.flex.col, walletStyle.nav]}>
          <Animated.View
            style={[theme.position.abs, theme.fill.fill, normalNavStyle]}>
            <NormalNav title={t('wallet.title')} />
          </Animated.View>
          <Animated.View
            style={[theme.position.abs, theme.fill.fill, detailNavStyle]}>
            <DetailNavTitle
              onBack={goBack}
              hideAmount
              title={t('wallet.title')}
              serverRight
            />
          </Animated.View>
        </View>
        <LazyImageBackground
          style={[theme.position.abs, walletStyle.topArea]}
          imageUrl={walletBackground}
          width={size.topBgWidth}
          height={size.topBgHeight}
        />
        <View
          style={{
            padding: size.l,
            rowGap: size.m * 2,
          }}>
          <WalletHeader totalWallet={amount} onRecharge={toRecharge} />
          <WalletMenus
            onRecharge={toRecharge}
            onTransfer={toTransfer}
            onWithdraw={toWithdraw}
          />
        </View>
        <WalletList list={gameWalletList} />
      </Animated.ScrollView>
    </View>
  );
};

export default WalletNew;
