import {View} from 'react-native';
import {useInnerStyle} from './wallet-new.hooks';
import React, {FC} from 'react';
import theme from '@/style';
import Text from '@/components/basic/text';
import {useTranslation} from 'react-i18next';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {QuestionIcon} from './svg.variables';
import {useQuestionModal} from './question.modal';
import i18n from '@/i18n';
import WalletListItem from './wallet-list-item';
import {useGamingModal} from './gaming.modal';
import {GameWalletListItem} from './wallet.service';

export interface WalletListProps {
  list: GameWalletListItem[];
}

const WalletList: FC<WalletListProps> = ({list}) => {
  const {size} = useInnerStyle();
  const {t} = useTranslation();
  const questionSize = size.calcActualSize(16);
  const {renderModal, show} = useQuestionModal({
    title: i18n.t('wallet.gameRemind'),
    content: i18n.t('wallet.tip.games'),
  });
  const {renderModal: renderGamingModal, show: showGaming} = useGamingModal();
  return (
    <View
      style={[
        {
          paddingHorizontal: size.l,
          paddingBottom: size.l,
          gap: size.s,
        },
        theme.flex.col,
      ]}>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          {
            columnGap: size.s,
          },
        ]}>
        <Text calc size="medium" color={theme.basicColor.white}>
          {t('wallet.game')}
        </Text>
        <NativeTouchableOpacity onPress={show}>
          <QuestionIcon
            style={{
              width: questionSize,
              height: questionSize,
            }}
          />
        </NativeTouchableOpacity>
      </View>
      <View
        style={[
          theme.flex.row,
          theme.flex.wrap,
          {
            gap: size.s,
          },
        ]}>
        {list.map(item => (
          <WalletListItem
            key={item.moduleName}
            gameName={item.moduleName}
            price={item.userBalance}
            icon={item.bgImgUrl}
            onPress={() =>
              showGaming({
                icon: item.bgImgUrl,
                title: item.moduleName,
                balance: item.userBalance,
              })
            }
          />
        ))}
      </View>
      {renderModal}
      {renderGamingModal}
    </View>
  );
};

export default WalletList;
