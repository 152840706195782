import {useModal} from '@/components/basic/modal';
import {View, Image} from 'react-native';
import {useInnerStyle} from './wallet-new.hooks';
import theme from '@/style';
import Text from '@/components/basic/text';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {iconClose} from './wallet.variable';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import LazyImage from '@/components/basic/image';
import Price from '@/components/business/price';

export interface GamingModalShowOptions {
  icon: string;
  title: string;
  balance: number;
}

export function useGamingModal() {
  const {size} = useInnerStyle();
  const modalWidth = size.calcActualSize(319);
  const closeSize = size.calcActualSize(24);
  const iconSize = size.calcActualSize(24);
  const {t} = useTranslation();
  const [options, setOptions] = useState<GamingModalShowOptions>();
  const handleShow = (_options?: GamingModalShowOptions) => {
    setOptions(_options);
    show();
  };
  const {renderModal, show, hide} = useModal(
    <View
      style={[
        theme.flex.col,
        theme.background.white,
        {
          rowGap: size.l,
          paddingHorizontal: size.l * 2,
          paddingBottom: size.l * 2,
          paddingTop: size.m * 2,
          borderRadius: size.calcActualSize(16),
          width: modalWidth,
        },
      ]}>
      <View
        style={[theme.flex.row, theme.flex.between, theme.flex.centerByCol]}>
        <Text calc main size="medium" blod>
          {t('wallet.game')}
        </Text>
        <NativeTouchableOpacity activeOpacity={1} onPress={() => hide()}>
          <Image
            source={iconClose}
            style={{
              width: closeSize,
              height: closeSize,
            }}
          />
        </NativeTouchableOpacity>
      </View>
      <View
        style={[
          theme.flex.row,
          theme.flex.between,
          theme.background.lightGrey,
          {
            borderRadius: size.calcActualSize(8),
            padding: size.l,
          },
        ]}>
        <View
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            {
              columnGap: size.xxl,
            },
          ]}>
          <LazyImage height={iconSize} imageUrl={options?.icon || ''} />
          <Text calc main size="medium" blod>
            {options?.title}
          </Text>
        </View>
        <View style={[theme.flex.col, theme.flex.alignEnd]}>
          <Text calc accent size="small">
            {t('label.balance')}
          </Text>
          <Price
            textProps={{
              second: true,
            }}
            price={options?.balance}
          />
        </View>
      </View>
      <Text calc accent size="small" style={[theme.font.center]}>
        {t('wallet.tip.gaming', {gameName: options?.title || ''})}
      </Text>
    </View>,
    {
      overlayStyle: {
        backgroundColor: 'transparent',
        shadowColor: 'transparent',
      },
    },
  );

  return {
    renderModal,
    show: handleShow,
  };
}
