import theme from '@/style';
import {FC} from 'react';
import {View, Image} from 'react-native';
import {useInnerStyle} from './wallet-new.hooks';
import React from 'react';
import Text from '@/components/basic/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {rechargeIcon, transferIcon, withdrawIcon} from './wallet.variable';
import {useTranslation} from 'react-i18next';

export interface WalletMenusHeader {
  onRecharge?: () => void;
  onWithdraw?: () => void;
  onTransfer?: () => void;
}
const WalletMenus: FC<WalletMenusHeader> = ({
  onRecharge,
  onWithdraw,
  onTransfer,
}) => {
  const {size} = useInnerStyle();
  const itemWidth = size.calcActualSize(64);
  const iconSize = size.calcActualSize(32);
  const {t} = useTranslation();

  return (
    <View
      style={[
        theme.flex.row,
        {
          paddingVertical: size.l,
          paddingHorizontal: size.l * 2,
          borderRadius: size.xxl,
        },
        theme.flex.between,
        theme.background.white,
      ]}>
      <NativeTouchableOpacity
        style={[
          {
            width: itemWidth,
            rowGap: size.s,
          },
          theme.flex.col,
          theme.flex.center,
        ]}
        onPress={onRecharge}>
        <Image
          source={rechargeIcon}
          style={{width: iconSize, height: iconSize}}
        />
        <Text calc size="small" second>
          {t('wallet.transferNav.recharge')}
        </Text>
      </NativeTouchableOpacity>
      <NativeTouchableOpacity
        style={[
          {
            width: itemWidth,
            rowGap: size.s,
          },
          theme.flex.col,
          theme.flex.center,
        ]}
        onPress={onWithdraw}>
        <Image
          source={withdrawIcon}
          style={{width: iconSize, height: iconSize}}
        />
        <Text calc size="small" second>
          {t('wallet.transferNav.withdraw')}
        </Text>
      </NativeTouchableOpacity>
      <NativeTouchableOpacity
        style={[
          {
            width: itemWidth,
            rowGap: size.s,
          },
          theme.flex.col,
          theme.flex.center,
        ]}
        onPress={onTransfer}>
        <Image
          source={transferIcon}
          style={{width: iconSize, height: iconSize}}
        />
        <Text calc size="small" second>
          {t('wallet.transferNav.transfer')}
        </Text>
      </NativeTouchableOpacity>
    </View>
  );
};

export default WalletMenus;
